// Imports

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import React, { Component, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import CountUp from 'react-countup'

import { suffixForNum, formatNum, decimalCount, decimalCountRatio } from '../../../../utils/numbers.js'
import ProfileCrawlLoader from './profile-crawl-loading.js'
import ShareButton from '../share-button/share-button.js'
import './profile-crawl.css'

// Sub Components

const Verified = ({isVerified, username}) => {
  if (isVerified) {
    const verifiedDataTip = '<span>@' + username + ' is a <b class="bold color-insta-verified">verified</b> account</span>'
    return (
      <Fragment>
        <img data-tip={verifiedDataTip} data-for='react-tooltip-profile-verified' alt="Verified" className="c-verified c-user-handles__icon c-user-handles__icon--verified pulse-on-display animation-delay-half-sec" src='/img/insta-verified.png' />
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-profile-verified' />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <img className="c-verified c-user-handles__icon c-user-handles__icon--verified hidden" alt="Verified" src='/img/insta-verified.png' />
      </Fragment>
    )
  }
}

const Real = ({isReal, username}) => {
  if (isReal) {
    const realDataTip = '<span>@' + username + ' is a <b class="bold color-green">real</b> account</span>'
    return (
      <Fragment>
        <FaCheckCircle data-tip={realDataTip} data-for='react-tooltip-profile-real' className="c-real c-user-handles__icon c-user-handles__icon--real pulse-on-display animation-delay-one-sec" alt="Real" />
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-profile-real' />
      </Fragment>
    
    )
  } else {
    const fakeDataTip = '<span>@' + username + ' is a <b class="bold color-red">fake</b> account</span>'
    return (
      <Fragment>
        <FaTimesCircle data-tip={fakeDataTip} data-for='react-tooltip-profile-fake' className="c-fake c-user-handles__icon c-user-handles__icon--fake pulse-on-display animation-delay-one-sec" alt="Fake" />
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-profile-fake' />
      </Fragment>
    
    )
  }
}

// Profile Crawl

class ProfileCrawl extends Component {
  render() {
    const { auditProfileCrawl, auditStatus } = this.props
    if (auditProfileCrawl === null ) {
      if (auditStatus !== null && auditStatus !== undefined && (auditStatus === 'failed' || auditStatus === 'aborted')) {
          return null
      } else {
        return <ProfileCrawlLoader />  
      }
    } else {
      const formattedPosts = formatNum(auditProfileCrawl.post_count)
      const decimalCountPosts = decimalCount(formattedPosts)
      const formattedFollowing = formatNum(auditProfileCrawl.follow_count)
      const decimalCountFollowing = decimalCount(formattedFollowing)
      const formattedFollowers = formatNum(auditProfileCrawl.followed_by_count)
      const decimalCountFollowers = decimalCount(formattedFollowers)
      const formattedRatio = formatNum(auditProfileCrawl.followed_by_to_follow_ratio)
      const decimalCountR = decimalCountRatio(formattedRatio)
      const profileURL = 'https://www.instagram.com/' + auditProfileCrawl.username
      return (
        <Fragment>
          <div className="c-profile-crawl-container">
            <div className="c-profile-crawl-container__left">
              <div className="c-user-profile-container">
                <a href={profileURL} className="c-user-profile__img-link" rel="noopener noreferrer" target="_blank"><img className="c-user-profile__img" alt="Avatar" src={auditProfileCrawl.profile_pic_url_hd}/></a>
                <div className="c-user-handles">
                  <h4 className="c-user-handles__username">
                    <a href={profileURL} className="c-user-profile__username--link" rel="noopener noreferrer" target="_blank">{auditProfileCrawl.username}</a>
                    <Verified isVerified={auditProfileCrawl.is_verified} username={auditProfileCrawl.username} />
                    <Real isReal={!auditProfileCrawl.is_fake} username={auditProfileCrawl.username} />
                  </h4>
                  <h6 className="c-user-handles__full-name">{auditProfileCrawl.fullname}<span></span></h6>
                </div>
              </div>
            </div>
            <div className="c-profile-crawl-container__right">
              <div className="c-user-stats">
                <div className="c-user-stats__stat">
                  <CountUp className="c-user-stats__count" delay={0.25} start={0} end={formattedPosts}
                   suffix={suffixForNum(auditProfileCrawl.post_count)} duration={1} decimals={decimalCountPosts}></CountUp>
                  <p className="c-user-stats__label">Posts</p>
                </div>
                <div className="c-user-stats__stat">
                  <CountUp className="c-user-stats__count" delay={0.5} start={0} end={formattedFollowing}
                   suffix={suffixForNum(auditProfileCrawl.follow_count)} duration={1} decimals={decimalCountFollowing}></CountUp>
                  <p className="c-user-stats__label">Following</p>
                </div>
                <div className="c-user-stats__stat">
                  <CountUp className="c-user-stats__count" delay={0.75} start={0} end={formattedFollowers}
                   suffix={suffixForNum(auditProfileCrawl.followed_by_count)} duration={0.75} decimals={decimalCountFollowers}></CountUp>
                  <p className="c-user-stats__label">Followers</p>
                </div>
                <div className="c-user-stats__stat">
                  <CountUp className="c-user-stats__count" delay={1} start={0} end={formattedRatio}
                  suffix={suffixForNum(auditProfileCrawl.followed_by_to_follow_ratio)} duration={1} decimals={decimalCountR}></CountUp>
                  <p className="c-user-stats__label">Ratio</p>
                </div>
              </div>
              <div className="c-share-button-container">
                <ShareButton />
              </div>
            </div>
          </div>
        </Fragment>      
      )
    }
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps)(ProfileCrawl);