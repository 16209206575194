// Imports

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ProfileCrawls from '../profile-crawls/profile-crawls.js'
import ProfileCrawl from '../profile-crawl/profile-crawl.js'
import AuditResults from '../audit-results/audit-results.js'
import delayUnmounting from '../../../../utils/delay.js'
import AuditStatus from '../status/status.js'

import './audit-data.css'

// Sub Components

const DelayedAuditStatus = delayUnmounting(AuditStatus)

// Audit Data

class AuditData extends Component {

  render() {
    const { mountLoader } = this.props
    return (
      <Fragment>
        <div className="c-audit-data-container">
          <DelayedAuditStatus delayTime={1750} isMounted={mountLoader} />
          <ProfileCrawl />
          <AuditResults />
          <ProfileCrawls />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
    mountLoader: state.loaderReducer.mount,
});

export default connect(mapStateToProps)(AuditData);