// Imports

import React from 'react';
import { FaInstagram, FaRegEnvelope } from "react-icons/fa";

import { Event } from '../tracking/tracking.js';

import './footer.css'

// Index

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="l-container c-footer">
          <div className="c-footer__section c-footer__section--left">
            <a href="/"><img className="c-footer-menu__logo" alt="FakeFriends Logo" src='/img/fakefriends-white.svg' /></a>
            <div className="c-footer-menu__links">
              <a className="c-footer__subsection c-footer__subsection--insta" href="https://instagram.com/fakefriends.io" onClick={()=> Event("OUTSIDE LINK", "Instagram")} target="_blank" rel="noopener noreferrer">
                <FaInstagram className="c-footer__insta-logo" size={15}/>
                <p className="c-span c-footer__insta-detail">Follow Us</p>
              </a>
              <a className="c-footer__subsection c-footer__subsection--contact" href="mailto:realfriend@fakefriends.io" onClick={()=> Event("CONTACT", "Email")}>
                <FaRegEnvelope className="c-footer__contact-logo" size={15}/>
                <p className="c-span c-footer__contact-detail">Contact Us</p>
              </a>
            </div>
          </div>
          <div className="c-footer__section c-footer__section--right">
            <p className="c-footer__copyright">© 2019 Fake Friends</p>
            <a className="c-footer__subsection c-footer__subsection--dsp" href="https://www.deepspaceprogram.com" onClick={()=> Event("OUTSIDE LINK", "Deep Space Program")} target="_blank" rel="noopener noreferrer">
              <img alt="DSP Logo" className="c-footer__dsp-logo" src='/img/dsp-logo.svg' />
              <p className="c-span c-footer__dsp-title"> Created by Deep Space Program</p>
            </a>
          </div>
        </div>
      </>
    )
  }
}

export default Footer;