// Utils

function suffixForNum(num) {
  if (num > 999999) {
    return 'm'
  } else if (num > 9999) {
    return 'k'
  } else {
    return ''
  }
}

function formatNum(num) {
  if (num > 999999) {
    return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1))
  } else if (num > 9999) {
    return Math.sign(num)*((Math.abs(num)/1000).toFixed(1))
  } else {
    return Math.sign(num)*((Math.abs(num)/1).toFixed(2))
  }
}

function decimalCount(num) {
  if (num > 100) {
    return 0
  } else {
    return (num % 1) !== 0 ? 1 : 0
  }
}

function decimalCountRatio(num) {
  if (num > 100) {
    return 0
  } else {
    return (num < 0.1) ? 2 : 1
  }
}

export { suffixForNum, formatNum, decimalCount, decimalCountRatio }