// Imports

import React from 'react';
import { connect } from 'react-redux';

import FakeFriendsAPI from '../../../clients/fakefriends.js'
import AuditData from './audit-data/audit-data.js'
import {
  auditCreated,
  auditFetchingFollowers,
  auditFetchingProfiles,
  auditAnalyzingResults,
  auditFinished,
  auditAborted,
  auditFailed
  } from '../../../actions/audit-status.js'
import {
  incrementAnalyzingStatus,
  resetAnalyzingStatus,
  } from '../../../actions/analyzing-status.js'
import {
  auditResultsReceived,
  auditProfileCrawlReceived,
  auditProfileCrawlsReceived,
  auditRecentPostsCrawlReceived,
  } from '../../../actions/audit-data.js'
import {
  loaderMount,
  loaderUnmount,
  } from '../../../actions/loader.js'


import '../pages.css'

// Index

class AuditPage extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    const { nextAuditStatus } = nextProps.auditStatus
    const { currentAuditStatus } = this.props.auditStatus
    if (nextAuditStatus !== currentAuditStatus) {
      return true
    } else {
      return false
    }
  }

  componentDidMount() {
    const { audit,
            auditCreated,
            auditFetchingFollowers,
            auditFetchingProfiles,
            auditAnalyzingResults,
            auditFinished,
            auditAborted,
            auditFailed,
            auditResultsReceived, 
            auditProfileCrawlReceived,
            auditProfileCrawlsReceived,
            auditRecentPostsCrawlReceived,
            loaderUnmount,
            incrementAnalyzingStatus,
            resetAnalyzingStatus } = this.props
    if (audit !== "undefined" && audit !== null) {
      const auditId = audit['id']
      var checkStatusAtInterval = setInterval(function() {
        FakeFriendsAPI.getAudit(auditId).then(response => {
          const status = response.status
          const auditResultsId = response.data.audit_results_id
          const auditProfileCrawlId = response.data.profile_crawl_id
          const auditPostsCrawlId = response.data.posts_crawl_id
          if (status === 200) {
            if (auditPostsCrawlId) {
              FakeFriendsAPI.getAuditRecentPostsCrawls(auditId).then(response => {
                auditRecentPostsCrawlReceived(response.data)
              })
            }
            if (auditProfileCrawlId) {
              FakeFriendsAPI.getAuditProfileCrawl(auditId).then(response => {
                auditProfileCrawlReceived(response.data)
              })
            }  
            const auditStatus = response.data.status
            switch (auditStatus) {
              case 'created':
                auditCreated()
                return response
              case 'fetching_followers':
                auditFetchingFollowers()
                return response
              case 'fetching_profiles':
                incrementAnalyzingStatus()
                auditFetchingProfiles()
                return response
              case 'analyzing':
                auditAnalyzingResults()
                return response
              case 'finished':
                FakeFriendsAPI.getAuditResults(auditResultsId).then(response => {
                  auditResultsReceived(response.data)
                })
                FakeFriendsAPI.getAuditResultsProfileCrawls(auditResultsId).then(response => {
                  auditProfileCrawlsReceived(response.data.results)
                })
                clearInterval(checkStatusAtInterval)
                auditFinished()
                loaderUnmount()
                resetAnalyzingStatus()
                return response
              case 'aborted':
                clearInterval(checkStatusAtInterval)
                auditAborted()
                resetAnalyzingStatus()
                return response
              default:
                clearInterval(checkStatusAtInterval)
                auditFailed()
                resetAnalyzingStatus()
                return response
            }
          }
        }).catch(error => {
          // Do nothing for now.
          resetAnalyzingStatus()
        })
      }, 4000)
    }
  }
  render() {
    return (
      <div className="c-page c-page--audit">
        <div className="l-container">
          <AuditData />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  auditCreated: () => dispatch(auditCreated()),
  auditFetchingFollowers: () => dispatch(auditFetchingFollowers()),
  auditFetchingProfiles: () => dispatch(auditFetchingProfiles()),
  auditAnalyzingResults: () => dispatch(auditAnalyzingResults()),
  auditFinished: () => dispatch(auditFinished()),
  auditAborted: () => dispatch(auditAborted()),
  auditFailed: () => dispatch(auditFailed()),
  auditResultsReceived: (...args) => dispatch(auditResultsReceived(...args)),
  auditProfileCrawlReceived: (...args) => dispatch(auditProfileCrawlReceived(...args)),
  auditProfileCrawlsReceived: (...args) => dispatch(auditProfileCrawlsReceived(...args)),
  auditRecentPostsCrawlReceived: (...args) => dispatch(auditRecentPostsCrawlReceived(...args)),
  loaderMount: () => dispatch(loaderMount()),
  loaderUnmount: () => dispatch(loaderUnmount()),
  incrementAnalyzingStatus: () => dispatch(incrementAnalyzingStatus()),
  resetAnalyzingStatus: () => dispatch(resetAnalyzingStatus())
});

const mapStateToProps = (state) => ({
    audit: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditPage);