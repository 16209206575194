// Imports

import { SubmissionError } from 'redux-form';
import debounce from 'debounce-promise';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import React, { Component, Fragment } from 'react';
import { Form } from "semantic-ui-react";
import { FaSearch, FaCheck, FaTimes } from "react-icons/fa";

import './search-bar-form.css';
import FakeFriendsAPI from '../../clients/fakefriends.js';
import { submitFormAuditUserHandler } from '../../actions/routines.js';
import { didBeginVerification, didEndVerification } from '../../actions/verify-username.js'
import store from '../../store.js'

// Validation

const required = value => value ? undefined : 'Required'

const unbouncedAsyncValidate = (values) => {
  store.dispatch(didBeginVerification())
  return FakeFriendsAPI.validateUsername(values.username).then(response => {
    console.log(response.data)
    const exists = response.data.exists
    if (!exists) {
      throw new SubmissionError({username : 'invalid'})
    }
    store.dispatch(didEndVerification())
  }).catch(error => {
    console.log(error)
    throw error
  })
}

var asyncValidate = debounce(unbouncedAsyncValidate, 400);

// Subscomponent

const ValidIcon = ({pristine, valid}) => {
  let validIcon = null
  let validIconDiv = null
  if (!pristine) {
      if (!valid) {
        validIcon = <FaTimes className="c-search-bar-form__icon c-search-bar-form__icon--invalid" size={16}/>
      } else {
        validIcon = <FaCheck className="c-search-bar-form__icon c-search-bar-form__icon--valid" size={16}/>
      }
    validIconDiv = <div className="c-search-bar-form__icon-container">{validIcon}</div>
    }
  return validIconDiv
}

const SearchButton = ({pristine, valid}) => {
  let searchButton = null
  const disabled = !(!pristine && valid)
  if (!valid) {
    searchButton = <button type="submit" className="c-search-bar-form__button c-search-bar-form__button--invalid" disabled={disabled}><FaSearch className="c-search-bar-form__icon c-search-bar-form__icon--search" size={15}/></button>
  } else {
    searchButton = <button type="submit" className="c-search-bar-form__button c-search-bar-form__button--valid" disabled={disabled}><FaSearch className="c-search-bar-form__icon c-search-bar-form__icon--search" size={15}/></button>
  }
  return searchButton
}
// Component

class SearchBarForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      didBeginVerification: false,
      didEndVerification: false,
    }
  }

  render() {
    const { valid, pristine } = this.props;
    var validBool = false
    if (this.props.didEndVerification) {
      validBool = valid
    }
    return (
      <Fragment>
        <form className="c-search-bar-form" onSubmit={this.props.handleSubmit(submitFormAuditUserHandler)}>
          <Form.Group className="c-search-bar-form__group">
            <div className="c-search-bar-form__container">
              <input style={{display: "none"}} type="text" name="usernamehidden"/>
              <input style={{display: "none"}} type="current-password" name="passwordhidden"/>
              <Field validate={[required]}  onFocus={(event) => event.preventDefault()} onBlur={(event) => event.preventDefault()} className="c-search-bar-form__input" component="input" name="username" type="username" placeholder="Enter a public Instagram" />
              <p className="c-search-bar-form__icon c-search-bar-form__icon--at">@</p>
              <SearchButton pristine={pristine} valid={validBool} />
              <ValidIcon pristine={pristine} valid={validBool} />
            </div>
          </Form.Group>
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
    loading: state.form.loading,
    didBeginVerification: state.verifyUsernameReducer.didBeginVerification,
    didEndVerification: state.verifyUsernameReducer.didEndVerification,
    auditData: state.submitFormAuditUserReducer.data
});

SearchBarForm = connect(mapStateToProps)(SearchBarForm);
export default reduxForm({ form: 'search', asyncValidate })(SearchBarForm);