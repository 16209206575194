// Imports

import { Progress } from 'semantic-ui-react'
import React, { Component } from 'react';
import ClassNames from 'classnames'
import Media from 'react-media';

// Relevancy Bar

class RelevancyBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realnessValue: '0', 
    }
  }

  componentDidMount () {
    let realness = this.props.realness
    let row = this.props.row
    setTimeout(
        function() {
            this.setState({ realnessValue: realness });
        }
        .bind(this),
        (250 * row) + 1000
    )
  }

  render () {
    const realnessValue = this.state.realnessValue
    const realnessColorValue = this.props.realness
    let useGreen = false
    let useYellow = false
    let useOrange = false
    let useRed = false
    if (realnessColorValue > 0.55) {
      useGreen = true
    } else if (realnessColorValue > 0.45) {
      useYellow = true
    } else if (realnessColorValue > 0.30) {
      useOrange = true
    } else {
      useRed = true
    }
    var progressBarClasses = ClassNames({
      'c-profile-crawl-col--relevancy-progress-bar': true,
      'background-color--green': useGreen,
      'background-color--yellow': useYellow,
      'background-color--orange': useOrange,
      'background-color--red': useRed,
    });

    return (
      <Media query="(min-width: 1200px)">
        {matches =>
          matches ? (
            <div className="c-profile-crawl-col--relevancy">
              <Progress className={progressBarClasses} value={realnessValue} total='1' progress='percent' precision={0} /> 
            </div>
          ) : (
            <div className="c-profile-crawl-col--relevancy c-profile-crawl-col--relevancy-mbl">
              <Progress className={progressBarClasses} value={realnessValue} total='1' progress='percent' precision={0} /> 
            </div>
          )
        }
      </Media>
    )
  }
}

export default RelevancyBar;