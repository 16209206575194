import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'

// Subcomponents

const ProfileLoader = () => (
  <ContentLoader 
    height={85}
    width={242}
    speed={2}
    primaryColor="#dfe1e5"
    secondaryColor="#ecebeb"
  >
    <circle cx="42" cy="43" r="42" /> 
    <rect x="98" y="25" rx="4" ry="4" width="120" height="18" /> 
    <rect x="98" y="54" rx="2" ry="2" width="74" height="9" />
  </ContentLoader>
)

// Loading

const ProfileCrawlLoader = () => {
  return (
      <Fragment>
        <div className="c-profile-crawl-container c-profile-crawl-placeholder">
          <div className="c-user-profile-container--profile-placeholder">
            <ProfileLoader />
          </div>
          <div className="c-user-stats">
              <div className="c-user-stats__stat">
                <h4 className="c-user-stats__count c-user-stats__count-loading">--</h4>
                <p className="c-user-stats__label c-user-stats__label--loading">Posts</p>
              </div>
              <div className="c-user-stats__stat">
                <h4 className="c-user-stats__count c-user-stats__count-loading">--</h4>
                <p className="c-user-stats__label c-user-stats__label--loading">Following</p>
              </div>
              <div className="c-user-stats__stat">
                <h4 className="c-user-stats__count c-user-stats__count-loading">--</h4>
                <p className="c-user-stats__label c-user-stats__label--loading">Followers</p>
              </div>
              <div className="c-user-stats__stat">
                <h4 className="c-user-stats__count c-user-stats__count-loading">--</h4>
                <p className="c-user-stats__label c-user-stats__label--loading">Ratio</p>
              </div>
            </div>
        </div>
      </Fragment> 
  )
}

export default ProfileCrawlLoader