// Imports

import { didBeginVerification, didEndVerification } from '../actions/verify-username.js'
import { handleActions } from 'redux-actions';

// Reducer

const verifyUsernameReducer = handleActions(
  {
    [didBeginVerification]: state => ({ ...state, didBeginVerification: true, didEndVerification: false}),
    [didEndVerification]: state => ({ ...state, didBeginVerification: false, didEndVerification: true})
  },
  {didBeginVerification: false, didEndVerification: false}
);

export default verifyUsernameReducer