// Imports

import axios from 'axios';

// Fake Friends API Client

class FakeFriendsAPI {

  constructor() {
    this.axiosConfig = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept' : 'application/json',
      "Access-Control-Allow-Origin": "*",
    }
  }

  validateUsername(username) {
    if (!username) {
      return Promise.reject({username: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/usernames/' + username
      let response = axios.get(endpoint)
      return response
    }
  }

  auditUsername(username) {
    if (!username) {
      return Promise.reject({username: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/audits/'
      var data = {'auditor_email': 'hq@deepspaceprogram.com',
                  'username': username,
                  'req_sample_size': 250}
      let response = axios.post(endpoint, data)
      return response
    }
  }

  getAudit(audit_id) {
    if (!audit_id) {
      return Promise.reject({audit_id: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/audits/' + audit_id
      let response = axios.get(endpoint)
      return response
    }
  }

    getAuditRecentPostsCrawls(audit_id) {
    if (!audit_id) {
      return Promise.reject({audit_id: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/audits/' + audit_id + '/crawls/posts'
      let response = axios.get(endpoint)
      return response
    }
  }

  getAuditProfileCrawl(audit_id) {
    if (!audit_id) {
      return Promise.reject({audit_id: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/audits/' + audit_id + '/crawls/profile'
      let response = axios.get(endpoint)
      return response
    }
  }

  getAuditResults(audit_results_id) {
    if (!audit_results_id) {
      return Promise.reject({audit_results_id: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/results/' + audit_results_id
      let response = axios.get(endpoint)
      return response
    }
  }

  getAuditResultsProfileCrawls(audit_results_id) {
    if (!audit_results_id) {
      return Promise.reject({audit_results_id: 'invalid'})
    } else {
      var API_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL;
      var endpoint = API_URL + '/v1/results/' + audit_results_id + '/crawls/profiles/?page=1'
      let response = axios.get(endpoint)
      return response
    }
  }
}

export default FakeFriendsAPI = new FakeFriendsAPI();