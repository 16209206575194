// Imports

import { createAction } from 'redux-actions';

// Actions

const auditResultsReceived = createAction('AUDIT_RESULTS_RECEIVED');
const auditProfileCrawlReceived = createAction('AUDIT_PROFILE_CRAWL_RECEIVED');
const auditProfileCrawlsReceived = createAction('AUDIT_PROFILE_CRAWLS_RECEIVED');
const auditRecentPostsCrawlReceived = createAction('AUDIT_RECENT_POSTS_CRAWLS_RECEIVED');

export {
  auditResultsReceived,
  auditProfileCrawlReceived,
  auditProfileCrawlsReceived,
  auditRecentPostsCrawlReceived,
}