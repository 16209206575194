// Imports

import ReactGA from "react-ga";

// Analytics

const initGA = (trackingID) => { 
   const testing = (process.env.NODE_ENV === 'test')          
   ReactGA.initialize(trackingID, { testMode: testing })
}

const PageView = () => {  
    ReactGA.pageview(window.location.pathname +  
                     window.location.search); 
}

const ModalView = (location) => {  
    ReactGA.modalview(location); 
}

const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export { initGA, PageView, ModalView, Event }