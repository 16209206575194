// Imports

import React from 'react';
import { connect } from 'react-redux';

import AccountsViewer from '../../accounts-viewer/accounts-viewer';
import MarketingCard from '../../marketing-card/marketing-card';
import { FlexRow, FullPageSection } from '../../layouts';
import '../pages.css';

// Index

class LandingPage extends React.Component {

  render() {
    return (
      <div className="c-page c-page--index">
        <div className="l-container">
          <FullPageSection justifyContent="flex-start">
            <FlexRow alignItems="flex-start" mx="auto" flexBasis={['100%', '100%', '75%']} height={["100vh", "100vh", "98%"]} overflowY="scroll" border="1px solid rgba(0,0,0,0.06)" borderRadius="8px" mt={["40px", "40px", "0px"]}>
              <AccountsViewer />
            </FlexRow>
            <FlexRow mx="auto" alignItems="flex-start" flexBasis={['100%', '100%', '25%']} pl={["0", "0", "20px"]} mt={["40px", "40px", "0"]} mb={["40px", "40px", "0"]} minHeight="0 !important" overflow="hidden">
              <MarketingCard />
            </FlexRow>
          </FullPageSection>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  latestAuditResults: state.audits.latestAuditResults,
});

export default connect(
  mapStateToProps,
  { }
)(LandingPage)