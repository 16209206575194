// Imports

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { color, flexbox, layout, space, typography } from 'styled-system';

import { PopularAccountRow, RowItem } from './accounts-rows';
import { FlexRow } from '../layouts';
import { Title } from '../headers';

// Sub Components

const Spacer = styled.div(
  color,
  flexbox,
  space,
  layout,
  typography,
)

// Index

class AccountsTable extends React.Component {

  static propTypes = {
    copyPopularAccounts: PropTypes.array.isRequired,
    auditResults: PropTypes.array.isRequired
  }

  render() {
    const { title } = this.props;
    if (this.props.copyPopularAccounts.length <= 0) {
      return <></>
    }
    if (this.props.auditResults.length > 0) {
      this.props.auditResults.sort((a, b) => (a.followers_count < b.followers_count) ? 1 : -1)
    }
    return (
      <FlexRow alignItems="flex-start">
        <FlexRow bg="rgba(255,255,255,1)" minHeight="60px" position="sticky" top="0" zIndex="3" borderTopLeftRadius="8px" borderTopRightRadius="8px">
          <Title variant="small" 
            pl="12px" 
            minHeight="60px" 
            lineHeight="62px !important" 
            color='primary' 
            fontFamily='Roboto'
            fontSize="18px !important" 
            fontWeight="600 !important" 
            verticalAlign="middle" zIndex="7 !important">
              {title}
          </Title>
        </FlexRow>
        <FlexRow bg="rgba(255,255,255,1)" flexWrap="nowrap" borderBottom="1px solid rgba(0,0,0,0.06)" minHeight="25px" position="sticky" top="42px" zIndex="4" pl="12px" pr="12px">
          <RowItem flexBasis={["60%", "37%", "30%"]} fontFamily="Roboto" fontSize="11px" fontWeight="400" textAlign="left" justifyContent="flex-start">USER</RowItem>
          <RowItem flexBasis={["17%", "14%", "14%"]} fontFamily="Roboto" fontSize="11px" fontWeight="400" color={color}>% REAL</RowItem>
          <RowItem flexBasis={["7%", "7%", "7%"]} display={["none", "none", "flex"]} fontFamily="Roboto" fontSize="11px" fontWeight="400" color={color}>RELEV</RowItem>
          <RowItem flexBasis={["23%", "26%", "23%"]} display={["none", "flex", "flex"]} fontFamily="Roboto" fontSize="11px" fontWeight="400">PROFESSION</RowItem>
          <RowItem flexBasis={["14%", "7%", "7%"]} display={["none", "flex", "flex"]} fontFamily="Roboto" fontSize="11px" fontWeight="400">COUNTRY</RowItem>
          <RowItem flexBasis={["23%", "15%", "18%"]} fontFamily="Roboto" fontSize="11px" fontWeight="400">FOLL</RowItem>
        </FlexRow>
        {this.props.auditResults.length > 0 && this.props.auditResults.map((result, index) => {
          const account = this.props.copyPopularAccounts.find(acc => {
            return result.username === acc.data.username[0].text;
          });
          const bg = index % 2 ? "white" : "primaryBg";
          if (account !== undefined) {
            return <PopularAccountRow account={account} auditResult={result} bg={bg} key={account.id}/>
          } else {
            return null;
          }
        })}
        <Spacer minHeight="12px" width="100%"></Spacer>
      </FlexRow>
    )
  }
}


const mapStateToProps = state => ({
  copyPopularAccounts: state.copy.popularAccounts,
  auditResults: state.audits.auditResults
});

export default connect(
  mapStateToProps,
  {}
)(AccountsTable)