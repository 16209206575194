// Imports

import {
  incrementAnalyzingStatus,
  resetAnalyzingStatus,
  } from '../actions/analyzing-status.js'
import { handleActions } from 'redux-actions';

// Reducer

const analyzingStatusReducer = handleActions(
  new Map([
    [incrementAnalyzingStatus, state => ({ ...state, analyzingStatus: state.analyzingStatus + 1 })],
    [resetAnalyzingStatus, state => ({ ...state, analyzingStatus: 0 })],
  ]),
  { analyzingStatus: 0 }
)

export default analyzingStatusReducer