export const GET_ERRORS = "GET_ERRORS";

export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const GET_AUDIT = "GET_AUDIT"

export const GET_AUDIT_RESULT = "GET_AUDIT_RESULT"
export const GET_AUDITS_RESULTS = "GET_AUDITS_RESULTS"
export const GET_POPULAR_AUDIT_RESULTS = "GET_POPULAR_AUDIT_RESULTS"
export const GET_LATEST_AUDIT_RESULTS = "GET_LATEST_AUDIT_RESULTS"

export const GET_FOLLOWER_PROFILES = "GET_FOLLOWER_PROFILES"

export const GET_RECENT_POSTS = "GET_RECENT_POSTS"

export const GET_USER_PROFILE = "GET_USER_PROFILE"

export const GET_COPY_POPULAR_ACCOUNTS = "GET_COPY_POPULAR_ACCOUNTS"

