// Imports

import { FaCheckCircle, FaTimesCircle, FaLock, FaMinus } from "react-icons/fa"
import React, { Component, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import Media from 'react-media'
import Numeral from 'numeral'

import { truncateText } from '../../../../utils/text.js'
import RelevancyBar from '../relevancy-bar/relevancy-bar.js'
import './profile-crawl-card.css'

// Subcomponents

const Real = ({isReal, username, row}) => {
  const animationDelaySec = String((0.250 * row) + 1)
  if (isReal) {
    const realDataTip = '<span>@' + username + ' is a <b class="bold color-green">real</b> account</span>'
    return (
      <div className="c-profile-crawl-col--real">
        <FaCheckCircle data-tip={realDataTip} data-for='react-tooltip-real' style={{animationDelay: animationDelaySec + 's'}} className="c-real pulse-on-display" alt="Real" />
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-real' />
      </div>
    
    )
  } else {
    const fakeDataTip = '<span>@' + username + ' is a <b class="bold color-red">fake</b> account</span>'
    return (
      <div className="c-profile-crawl-col--real">
        <FaTimesCircle data-tip={fakeDataTip} data-for='react-tooltip-fake' style={{animationDelay: animationDelaySec + 's'}} className="c-fake pulse-on-display" alt="Fake" />
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-fake' />
      </div>
    
    )
  }
}

const Count = ({count, classNames}) => {
  return <h5 className={classNames}>{count}</h5>
}

const Private = ({isPrivate, username}) => {
  const privateDataTip = '<span>@' + username + ' is a <b class="bold">private</b> account</span>'
  if (isPrivate) {
    return (
      <div className="c-profile-crawl-col--private">
        <FaLock data-tip={privateDataTip} data-for='react-tooltip-private' className="c-lock" size={14}/>
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-private' />
      </div>
    )
  } else {
    return (
      <Media query="(min-width: 1200px)">
        {matches =>
          matches ? (
            <div className="c-profile-crawl-col--private">
              <FaMinus className="c-minus" size={14}/>
            </div>
          ) : (
            null
          )
        }
      </Media>
    )
  }
}

const Verified = ({isVerified, username}) => {
  if (isVerified) {
    const verifiedDataTip = '<span>@' + username + ' is a <b class="bold color-insta-verified">verified</b> account</span>'
    return (
      <div className="c-profile-crawl-col--verified">
        <img data-tip={verifiedDataTip} data-for='react-tooltip-verified' className="c-verified pulse-on-display animation-delay-one-sec" alt="Verified" src='/img/insta-verified.png'/>
        <ReactTooltip className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-verified' />
      </div>
    )
  } else {
    return (
      <Media query="(min-width: 1200px)">
        {matches =>
          matches ? (
            <div className="c-profile-crawl-col--verified">
              <FaMinus className="c-minus" size={14}/>
            </div>
          ) : (
            null
          )
        }
      </Media>
    )
  }
}

// Profile Crawl Row

class ProfileCrawlCard extends Component {
  render() {
    const row = this.props.row
    const profile = this.props.profile
    const truncatedUsername = truncateText(profile.username, 24, '...')
    const profileURL = 'https://www.instagram.com/' + profile.username + '/'
    const formattedRatio = (profile.followed_by_to_follow_ratio < 0.1) ? Numeral(profile.followed_by_to_follow_ratio).format('0.00') : Numeral(profile.followed_by_to_follow_ratio).format('0.0')
    return (
      <Fragment>
        <div className="c-profile-crawl-card">
          <div className="c-profile-crawl-card__row c-profile-crawl-card__row--one">
            <div className="c-profile-crawl-card__left-col">
              <a className="c-profile-crawl-card__avatar-link" href={profile.profile_pic_url} rel="noopener noreferrer" target="_blank">
                <img className="c-profile-crawl-card__avatar" alt="Avatar" src={profile.profile_pic_url}/>
              </a>
              <a className="c-profile-crawl-crawl__username" href={profileURL} rel="noopener noreferrer" target="_blank">
                @{truncatedUsername}
              </a>
            </div>
            <div className="c-profile-crawl-card__right-col">
              <Real isReal={!profile.is_fake} username={profile.username} row={row}/>
              <Private isPrivate={profile.is_private} username={profile.username}  row={row} />
              <Verified isVerified={profile.is_verified} username={profile.username}  row={row}/>
            </div>
          </div>
          <div className="c-profile-crawl-card__row c-profile-crawl-card__row--two">
            <div className="c-profile-card__stat c-profile-card__stat--followers">
              <h5 className="c-profile-card__stat-label">Followers</h5>
              <Count classNames="c-profile-card__stat-title" count={Numeral(profile.followed_by_count).format()} />
            </div>
            <div className="c-profile-card__stat c-profile-card__stat--following">
              <h5 className="c-profile-card__stat-label">Following</h5>
              <Count classNames="c-profile-card__stat-title" count={Numeral(profile.follow_count).format()} />
            </div>
            <div className="c-profile-card__stat c-profile-card__stat--ratio">
              <h5 className="c-profile-card__stat-label">Ratio</h5>
              <Count classNames="c-profile-card__stat-title" count={formattedRatio}  />
            </div>
            <div className="c-profile-card__stat c-profile-card__stat--posts">
              <h5 className="c-profile-card__stat-label">Posts</h5>
              <Count classNames="c-profile-card__stat-title" count={Numeral(profile.post_count).format()} />
            </div>
          </div>
          <div className="c-profile-crawl-card__row c-profile-crawl-card__row--three">
            <RelevancyBar realness={profile.realness} row={row}/>
          </div>
        </div>
      </Fragment>      
    )
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.data,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps)(ProfileCrawlCard);