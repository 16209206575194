// Imports

import React, { Component, Fragment } from 'react'
import { FaInfoCircle } from "react-icons/fa"
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import Media from 'react-media'
import Bowser from "bowser"

import ProfileCrawlCard from '../profile-crawl-card/profile-crawl-card.js'
import ProfileCrawlRow from '../profile-crawl-row/profile-crawl-row.js'
import './profile-crawls.css'

// Const

const profileCrawlsDataTip = 'Twenty followers randomly selected from the sample used to perform the audit.'

// Utils

function compareRelevancy( a, b ) {
  if ( a.realness < b.realness ){
    return 1;
  }
  if ( a.realness > b.realness ){
    return -1;
  }
  return 0;
}

// Profile Crawls

class ProfileCrawls extends Component {
  render() {
    const ios = Bowser.ios
    const profiles = this.props.auditProfileCrawls
    if (profiles !== "undefined" && profiles !== null) {
      profiles.sort(compareRelevancy)
      return (
        <Fragment>
          <Media query="(min-width: 1200px)">
            {matches =>
              matches ? (
                <div className="c-profile-crawls-container">
                  <div className="c-profile-crawls__header">
                    <h5 className="c-profile-crawls__header-title">Followers Sample</h5>
                    <FaInfoCircle data-tip={profileCrawlsDataTip} data-for='react-tooltip-profile-crawls' className="c-info-circle" size={16}/>
                    <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='left' border={true} multiline={true} html={true} id='react-tooltip-profile-crawls' />
                  </div>
                  <div className="c-profile-crawls-table__header">
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--user">User</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--real">Real</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--relevancy">Relevancy</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--followers">Followers</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--following">Following</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--ratio">Ratio</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--posts">Posts</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--private">Private</h6>
                    <h6 className="c-profile-crawls-table__title c-profile-crawls-table__title--private">Verified</h6>
                  </div>
                  <div className="c-profile-crawls-table">
                    {profiles.map((profile, index) => {
                      return <ProfileCrawlRow profile={profile} row={index}/>
                    })}
                  </div>
                </div>
              ) : (
                <div className="c-profile-crawls-container">
                  <div className="c-profile-crawls__header">
                    <h5 className="c-profile-crawls__header-title">Followers Sample</h5>
                    <FaInfoCircle data-tip={profileCrawlsDataTip} data-for='react-tooltip-profile-crawls' className="c-info-circle" size={16}/>
                    <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='top' border={true} multiline={true} html={true} id='react-tooltip-profile-crawls' />
                  </div>
                  <div className="c-profile-crawls-table c-profile-crawls-table--mobile">
                    {profiles.map((profile, index) => {
                      return <ProfileCrawlCard profile={profile} row={index}/>
                    })}
                  </div>
                </div>
              )
            }
          </Media>

        </Fragment>      
      )
    } else {
      return (
        <Fragment>
          <div></div>
        </Fragment>
      )
    }
    
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.data,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps)(ProfileCrawls);