// Imports

import {
  GET_AUDIT,
  GET_AUDIT_RESULT,
  GET_AUDITS_RESULTS,
  GET_POPULAR_AUDIT_RESULTS,
  GET_FOLLOWER_PROFILES,
  GET_RECENT_POSTS,
  GET_USER_PROFILE,
  GET_LATEST_AUDIT_RESULTS
} from '../../actions/types';

// State

const initialState = {
  audits: [],
  auditResults: [],
  auditFollowerProfiles: [],
  auditRecentPosts: [],
  auditUserProfiles: [],
  latestAuditResults: []
};

// Helpers

function replaceOrAddToArray(array, action) {
  let inArray = false
  let newArray = array.map((item, index) => {
    if (item.id !== action.payload.id) {
      return item
    } else {
      inArray = true
      return {
        ...action.payload
      }
    }
  })
  if (inArray === false) {
    return [...newArray, action.payload]
  }
}

// Reducer

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AUDIT:
      return {
        ...state,
        audits: replaceOrAddToArray(state.audits, action)
      };
    case GET_AUDIT_RESULT:
      return {
        ...state,
        audits: replaceOrAddToArray(state.audits, action)
      };
    case GET_AUDITS_RESULTS:
      return {
        ...state,
        auditResults: action.payload.results.map(obj => state.auditResults.find(o => o.id === obj.id) || obj)
      }
    case GET_POPULAR_AUDIT_RESULTS:
      return {
        ...state,
        auditResults: action.payload.results.map(obj => state.auditResults.find(o => o.id === obj.id) || obj)
      };
    case GET_FOLLOWER_PROFILES:
      return {
        ...state,
        auditFollowerProfiles: replaceOrAddToArray(state.auditFollowerProfiles, action)
      };
    case GET_RECENT_POSTS:
      return {
        ...state,
        auditRecentPosts: replaceOrAddToArray(state.auditRecentPosts, action)
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        auditUserProfiles: replaceOrAddToArray(state.auditUserProfiles, action)
      };
    case GET_LATEST_AUDIT_RESULTS:
      let newArray = action.payload.results.map(obj => state.latestAuditResults.find(o => o.id === obj.id) || obj)
      return {
        ...state,
        latestAuditResults: newArray.slice(0, 120)
      };
    default:
      return state;
  }
}
