// Imports

import React from 'react';
import { CircleSpinner } from "react-spinners-kit";

import './loading.css'

// Index

class Loading extends React.Component {
  render() {
    return (
      <div className="l-container c-page c-page--loading">
        <CircleSpinner size={20} color="#0029FF" loading={true} />
      </div>
    )
  }
}

export default Loading