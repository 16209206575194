// Imports

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider as AlertProvider } from 'react-alert';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'semantic-ui-less/semantic.less';
import './App.css';

import PreloadedIndexPageWrapper from './components/wrappers/preloaded-index-page-wrapper';
import { PageView, initGA } from './components/tracking/tracking';
import AlertTemplate from './components/alerts/alert-template';
import FFTicker from './components/ffticker/ffticker';
import IndexPage from './components/pages/index/index';
import MainNav from './components/main-nav/main-nav';
import { lightTheme } from './styles/themes.js';
import Footer from './components/footer/footer';
import Alerts from './components/alerts/alerts';

// Options

const alertOptions = {
  timeout: 3000,
  position: 'bottom center',
  containerStyle: {
    zIndex: 5000
  }
}

// App

class App extends React.Component {

  componentWillMount() {
    initGA('UA-145465444-1')
    PageView()
  }

  render() {
    return (
      <ThemeProvider theme={lightTheme}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <BrowserRouter>
            <Alerts />
            <div>
              <MainNav />
              <Switch>
                <Route path="/u/:username" component={ PreloadedIndexPageWrapper } />
                <Route exact path="/" component={IndexPage} />
              </Switch>
              <FFTicker />
              <Footer />
            </div>
          </BrowserRouter>
        </AlertProvider>
      </ThemeProvider>
    )
  }
}

export default App;
