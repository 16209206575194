// Imports

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAuditsResults } from '../../actions/api/audit-results';
import { getCopyPopularAccounts } from '../../actions/api/copy';
import AccountsTable from './accounts-table';

// Index

class AccountsViewer extends React.Component {

  static propTypes = {
    getCopyPopularAccounts: PropTypes.func.isRequired,
    copyPopularAccounts: PropTypes.array.isRequired,
    getAuditsResults: PropTypes.func.isRequired
  }

  componentDidUpdate(prevProps) {
    const prevPopularAccounts = prevProps.copyPopularAccounts;
    const popularAccounts = this.props.copyPopularAccounts;
    if ((popularAccounts.length > 0) && (prevPopularAccounts !== popularAccounts)) {
      const usernames = popularAccounts.map((acc, i) => { return acc.data.username[0].text });
      this.props.getAuditsResults(usernames, true);
    }
  }

  componentDidMount() {
    this.props.getCopyPopularAccounts();
  }

  render() {
    return (
      <AccountsTable title="Popular Accounts" accounts={this.props.copyPopularAccounts} />
    )
  }
}


const mapStateToProps = state => ({
  copyPopularAccounts: state.copy.popularAccounts,
  auditResults: state.audits.auditResults
});

export default connect(
  mapStateToProps,
  { getCopyPopularAccounts, getAuditsResults }
)(AccountsViewer)