// Base URLs (to be used for constructing our endpoint URLs)

const BASE_URL = process.env.REACT_APP_FAKEFRIENDS_API_URL

const API_URL = "/api"

const VERSION_URL = "/v3"

const ENDPOINT = `${BASE_URL}${API_URL}${VERSION_URL}`

// FF Endpoints

export const auditURL = (auditPk) => `${ENDPOINT}/audits/${auditPk}/`;

export const auditResultURL = (auditPk) => `${ENDPOINT}/audits/${auditPk}/`;
export const auditsResultsURL = `${ENDPOINT}/audits/results/`;
export const auditFollowerProfilesURL = (auditPk) => `${ENDPOINT}/audits/${auditPk}/follower-profiles/`;
export const auditUserProfileURL = (auditPk) => `${ENDPOINT}/audits/${auditPk}/crawls/user-profile/`;
export const auditRecentPostsURL = (auditPk) => `${ENDPOINT}/audits/${auditPk}/crawls/recent-posts/`;

