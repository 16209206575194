// Imports

import React, { Component, Fragment } from 'react';
import { CircleSpinner } from "react-spinners-kit";
import { FaLock } from "react-icons/fa"
import { connect } from 'react-redux';

import './status.css'

// Subcomponents

const Ellipses = ({show}) => {
  if (show) {
    return (
      <Fragment>
        <span className="c-ellipse c-ellipse--one">.</span>
        <span className="c-ellipse c-ellipse--two">.</span>
        <span className="c-ellipse c-ellipse--three">.</span>
      </Fragment>
    )
  } else {
    return null
  }
}

const Private = ({show}) => {
  if (show) {
    return <FaLock className="c-lock c-audit-status__icon c-audit-status__icon--private" size={18}/>
  } else {
    return null
  }
}

// Audit Status

class AuditStatus extends Component {

  render() {
    let { audit, auditStatus, mountLoader } = this.props
    let loading = mountLoader
    let titleColor = 'var(--blue)'
    let auditTitle = "Loading"
    let auditDetail = "We're waiting to begin your audit!"
    let showElipses = true
    let showLock = false
    let fullPage = false
    switch (auditStatus) {
      case 'created':
        auditTitle = 'Starting'
        auditDetail = "Sit tight while we begin processing your audit!"
        break
      case 'followers':
        auditTitle = 'Collecting Followers'
        auditDetail = "We're gathering 250 random accounts to use as our sample."
        break
      case 'profiles':
        auditTitle = 'Analyzing'
        auditDetail = "We're collecting information from each account in the sample."
        break
      case 'analyzing':
        auditTitle = 'Calculating Score'
        auditDetail = "We're evaluating the realness and relevenacy of each account."
        break
      case 'finished':
        loading = false
        showElipses = false
        auditTitle = 'Success!'
        titleColor = 'var(--green)'
        auditDetail = "Your results are ready."
        break
      case 'aborted':
        fullPage = true
        showElipses = false
        auditTitle = 'Cancelling'
        auditDetail = "Give us a quick second while we canel your audit."
        break
      case 'failed':
        fullPage = true
        loading = false
        showElipses = false
        if (audit.error === "private_account") {
          showLock = true
          titleColor = 'var(--black)'
          auditTitle = 'Private Account'
          auditDetail = "We don't support private audits...yet. Stay tuned!"
        } else {
          auditTitle = 'Oops!'
          titleColor = 'var(--red)'
          auditDetail = "We ran into some trouble. Please try again."
        }
        break
      default:
        auditTitle = 'Starting'
        auditDetail = "Sit tight while we begin processing your audit!"
        break

    }
    return (
      <div className="c-audit-status-container">
        <div className={`c-audit-status ${mountLoader ? "" : " c-audit-status--hidden"} ${!fullPage ? "" : " c-audit-status--fullpage"}`}>
          <div className="c-audit-status__loader">
            <h5 className="c-audit-status__title" style={{color: titleColor}}>
              {auditTitle}
              <Ellipses show={showElipses} />
              <Private show={showLock} />
            </h5>
            <h5 className="c-audit-status__detail">{auditDetail}</h5>
            <div className="c-audit-status__spinner">
              <CircleSpinner size={25} color="#0029FF" loading={loading} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    audit: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
    mountLoader: state.loaderReducer.mount,
});

export default connect(mapStateToProps)(AuditStatus);