// Imports

import { createAction } from 'redux-actions'

// Actions

const auditIdle = createAction('AUDIT_IDLE')
const auditCreated = createAction('AUDIT_CREATED')
const auditFetchingFollowers = createAction('AUDIT_FETCHING_FOLLOWERS')
const auditFetchingProfiles = createAction('AUDIT_FETCHING_PROFILES')
const auditAnalyzingResults = createAction('AUDIT_ANALYZING_RESULTS')
const auditFinished = createAction('AUDIT_FINISHED')
const auditAborted = createAction('AUDIT_ABORTED')
const auditFailed = createAction('AUDIT_FAILED')

export {
  auditIdle,
  auditCreated,
  auditFetchingFollowers,
  auditFetchingProfiles,
  auditAnalyzingResults,
  auditFinished,
  auditAborted,
  auditFailed
}