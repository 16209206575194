// Imports

import ContentLoader from 'react-content-loader'
import React, { Component, Fragment } from 'react'
import { CircleSpinner } from "react-spinners-kit";
import { connect } from 'react-redux'
import Media from 'react-media'

// Subcomponents

const PieChartLoader = ({classNames}) => {
  return (
    <ContentLoader 
      height={188}
      width={236}
      speed={2}
      primaryColor="#f0f0f0"
      secondaryColor="#fafafa"
    >
      <rect x="12" y="12" rx="2" ry="2" width="140" height="12" /> 
      <rect x="12" y="36" rx="4" ry="4" width="212" height="140" /> 
    </ContentLoader>
  )
}

const SampleSizeLoader = ({classNames}) => {
  return (
    <ContentLoader 
      height={188}
      width={236}
      speed={2}
      primaryColor="#f0f0f0"
      secondaryColor="#fafafa"
    >
      <rect x="12" y="12" rx="2" ry="2" width="120" height="12" /> 
      <circle cx="62" cy="106" r="50" /> 
      <circle cx="174" cy="106" r="50" />
    </ContentLoader>
  )
}

const TableLoader = ({classNames}) => {
  return (
    <ContentLoader 
      height={188}
      width={236}
      speed={2}
      primaryColor="#f0f0f0"
      secondaryColor="#fafafa"
    >
      <rect x="12" y="12" rx="4" ry="4" width="212" height="46" /> 
      <rect x="12" y="70" rx="4" ry="4" width="212" height="46" /> 
      <rect x="12" y="128" rx="4" ry="4" width="212" height="47" /> 
    </ContentLoader>
  )
}

const FirstCardContent = ({analyzingStatus}) => {
  if (analyzingStatus > 0) {
    return (
      <div className="c-audit-results-card">
        <PieChartLoader />
      </div>
    )
  } else {
    return ( 
      <CircleSpinner size={20} color="#E7E7E7" loading={true} />
    )
  }
}

const SecondCardContent = ({analyzingStatus}) => { 
  if (analyzingStatus > 1) {
    return (
      <div className="c-audit-results-card">
        <PieChartLoader />
      </div>
    )
  } else {
    return (
      <CircleSpinner size={20} color="#E7E7E7" loading={true} />
    )
  }
}

const ThirdCardContent = ({analyzingStatus}) => {
  if (analyzingStatus > 2) {
    return (
      <div className="c-audit-results-card">
        <SampleSizeLoader />
      </div>
    )
  } else {
    return ( 
      <CircleSpinner size={20} color="#E7E7E7" loading={true} />
    )
  }
}

const FourthCardContent = ({analyzingStatus}) => {
  if (analyzingStatus > 3) {
    return (
      <div className="c-audit-results-card">
        <TableLoader />
      </div>
    )
  } else {
    return (  
      <CircleSpinner size={20} color="#E7E7E7" loading={true} />      
    )
  }
}

// Loading

class AuditResultsLoading extends Component {

  render () {
    const { analyzingStatus } = this.props
    return (
      <Media query="(min-width: 584px)">
        {matches =>
          matches ? (
            <Fragment>
              <div className="c-audit-results-container">
                <div className="c-audit-results-card__container">
                  <FirstCardContent analyzingStatus={analyzingStatus} />
                </div>
                <div className="c-audit-results-card__container">
                  <SecondCardContent analyzingStatus={analyzingStatus} />
                </div>
                <div className="c-audit-results-card__container ">
                  <ThirdCardContent analyzingStatus={analyzingStatus} />
                </div>
                <div className="c-audit-results-card__container">
                  <FourthCardContent analyzingStatus={analyzingStatus} />
                </div>
              </div>
            </Fragment>
          ) : (
            null
          )
        }
      </Media>
    )
  }
}

const mapStateToProps = (state) => ({
    auditStatus: state.auditStatusReducer.status,
    analyzingStatus: state.analyzingStatusReducer.analyzingStatus
});

export default connect(mapStateToProps)(AuditResultsLoading);