// Imports

import React from 'react';
import { connect } from 'react-redux';

import Accordion from '../../accordion/accordion'
import '../pages.css'
import './faq.css'

// FAQ 

class FAQPage extends React.Component {

  render() {

    const fakeFriends = "FakeFriends is a fast and reliable tool for" +
      " authenticating an Instagram account. Using an internal algorithm we" + 
      " calculate the estimated percent of real followers, and provide" +
      " additional insights into an account's following."
    const howDoesItWork = "First, we fetch profile and post information from the" +
      " audited account, and gather a sample of random followers." +
      " Next, we run each fetched follower through the FakeFriends algorithm to" +
      " determine whether it is 'real' or 'fake' and assign it a score. Finally," +
      " we compile this data into an average and report it back to you. All of" +
      " this happens in under 15 seconds!"
    const realFriend = "We define a 'real' friend as an active and" +
      " contributing <i>human</i> within the Instagram community."
    const algorithm = "That's our secret sauce, so it stays under lock and key!" +
      " But, rest assured that it's accurate...and improving by the day."
    const whyNot100 = "In an effort to appear more human, and avoid detection" +
      " from tools like FakeFriends, bots will follow random accounts." +
      " Unless yours is set to private, it's difficult to prevent" +
      " 'fake' friends from following you and hard to score over a 75%."
    const marginOfError = "This is because we pull a random sample of followers when" +
      " performing an audit. So, there will be a small margin of error" +
      " (don't worry, it's around 5% or less). "
    const notPerfect = "Our algorithm isn't perfect...yet. Please report your" +
      " account to help us train our machine learning."
    const sampleSize = "This paid feature is coming soon! Can't wait?" +
      " Feel free to contact us at" +
      " <a href='mailto:realfriends@fakefriends.io'>realfriends@fakefriends.io</>."
    const privateAccounts = "Yes! Stay tuned."
    const compareAccounts = "This feature will be added in FakeFriends" +
      " Version 2.0, alongside group audits, trending charts, and much," + 
      " much more!"

    const faqs = [
      ["What is a 'real' friend?", realFriend],
      ["How does FakeFriends work?", howDoesItWork],
      ["How does the FakeFriends algorithm work?", algorithm],
      ["I've never bought followers—why didn't I score 100%?", whyNot100],
      ["Will someone know if I audit their profile?", "Nope! FakeFriends is 100% anonymous."],
      ["Why do I see different results when I audit a profile twice?", marginOfError],
      ["I'm real, but FakeFriends says I'm fake—why?", notPerfect],
      ["Can I audit an account using a bigger sample size?", sampleSize],
      ["Are audits of private accounts coming soon?", privateAccounts],
      ["Can I can compare accounts on FakeFriends?", compareAccounts],
    ]

    return (
      <div className="c-page c-page--faq">
        <div className="l-container background-color--black">
          <div className="c-faq">
            <h3 className="c-faq__title">What is FakeFriends?</h3>
            <p className="c-faq__whatis">
              {fakeFriends}
            </p>
            <h3 className="c-faq__title">Frequently Asked Questions</h3>
            {faqs.map((faq, index) => {
              return <Accordion eventTitle={faq[0]} title={faq[0]} content={faq[1]} tag={faq[0]} />
            })}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.audit,
});

export default connect(mapStateToProps)(FAQPage);