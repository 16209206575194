// Imports

import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { ModalView } from '../../tracking/tracking.js'
import LandingPage from '../landing/landing.js'
import LoadingPage from '../loading/loading.js'
import AuditPage from '../audit/audit.js'
import FAQPage from '../faq/faq.js'

import '../pages.css'
import './index.css'

// Index

class IndexPage extends React.Component {

  render() {
    const { auditData, resetting, showFAQ } = this.props
    if (showFAQ) {
      ModalView('/faq')
      return (
        <Fragment>
          <FAQPage />
        </Fragment>
      )
    } else {
      if (resetting) {
        ModalView('/loading')
        return (
          <Fragment>
            <div className="c-sticky-cover"> </div>
            <LoadingPage />
          </Fragment>
        )
      } else if (auditData !== "undefined" && auditData !== null) {
        ModalView('/audit')
        return (
          <Fragment>
            <div className="c-sticky-cover"> </div>
            <AuditPage />
          </Fragment>
        )
      } else {
        ModalView('/landing')
        return (
          <Fragment>
            <div className="c-sticky-cover"> </div>
            <LandingPage />
          </Fragment>
        )
      }
    }
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditStatusReducer.results,
    resetting: state.auditResetReducer.resetting,
    showFAQ: state.modalReducer.faq,
});

export default connect(mapStateToProps)(IndexPage);