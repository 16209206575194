// Imports

import {
  startAuditReset,
  endAuditReset,
  } from '../actions/reset.js'
import { handleActions } from 'redux-actions';

// Reducer

const auditResetReducer = handleActions(
  new Map([
    [startAuditReset, state => ({ ...state, resetting: true })],
    [endAuditReset, state => ({ ...state, resetting: false })],
  ]),
  { resetting: false }
)

export default auditResetReducer