// Imports

import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components'
import { color, layout, space } from 'styled-system'

// Animation

const tickAnimation = keyframes`
  0%   { transform: translateX(calc(0% + 100vw)); }
  5%   { transform: translateX(calc(0% + 100vw)); }
  100% { transform: translateX(-100%); }
`

// Components

const TickerWrapper = styled.div`
  align-items: center;
  background-color: #000;
  border-top: 1px solid rgba(0,41,255, 0.75);
  bottom: 0;
  height: 42px;
  overflow: hidden;
  position: sticky;
  width: 100vw;
  @media(max-width: 1075px) {
    bottom: 70px;
  }
`

const Ticker = styled.div`
  align-items: center;
  background-color: #000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 42px;
  justify-content: flex-start;
  animation: 80s ${tickAnimation} linear infinite;
`

const TickerUsername = styled(Link) (
  {
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'Helvetica Neue, Helvetica, Arial',
    fontSize: '13px',
    fontWeight: 'bold',
    height: '20px',
    lineHeight: '20px',
    marginLeft: '32px',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
    }
  },
  color,
  space,
  layout,
)

const TickerPercent = styled.span (
  {
    fontFamily: 'Helvetica Neue, Helvetica, Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '4px',
  },
  color,
  space,
  layout,
)

export { TickerWrapper, Ticker, TickerUsername, TickerPercent, tickAnimation }
