function getRealFollowersColorForPercent(percent) {
  if (percent > 0.6) {
    return '#01C58E'
  } else if (percent > 0.45) {
    return '#FEDA01';
  } else if (percent > 0.35) {
    return '#FF7702';
  } else {
    return '#E4373F'
  }
}

function getFollowerRelevancyColorForPercent(percent) {
  if (percent > 0.55) {
    return '#01C58E'
  } else if (percent > 0.46) {
    return '#FEDA01';
  } else if (percent > 0.30) {
    return '#FF7702';
  } else {
    return '#E4373F'
  }
}

export { getRealFollowersColorForPercent, getFollowerRelevancyColorForPercent }