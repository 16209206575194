// Imports

import { submitFormAuditUserRoutine } from '../actions/routines.js';

// Reducers

const initialState = {
  audit: null,
  loading: false,
  error: null,
};

export default function submitFormAuditUserReducer(state=initialState, action) {
  switch (action.type) {
    case submitFormAuditUserRoutine.TRIGGER:
      return {
        ...state,
        loading: true,
        audit: null,
      }
    case submitFormAuditUserRoutine.SUCCESS:
      return {
        ...state,
        audit: action.payload,
      }
    case submitFormAuditUserRoutine.FAILURE:
      return {
        ...state,
        error: action.payload,
        audit: null,
      }
    case submitFormAuditUserRoutine.FULFILL:
      return {
        ...state,
        loading: false,
      }
    case 'START_AUDIT_RESET':
      return {
        ...state,
        loading: false,
        error: null,
        audit: null,
      }
    default:
      return state;
  }
}