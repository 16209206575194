// Imports

import { FaRegLightbulb, FaRegTimesCircle } from "react-icons/fa"
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import store from '../../store.js'
import { Event } from '../tracking/tracking.js'
import { showFAQ, hideFAQ } from '../../actions/faq.js';
import SearchBarForm from '../search-bar-form/search-bar-form.js'

import './main-nav.css'

// Subcomponents

const ResultsExplainedButton = (props) => {
  const { isShowingFAQ, handleClick } = props
  if (isShowingFAQ === true) {
    return (
      <Fragment>
        <button className="c-main-nav__button c-main-nav__button--close" name="faq" onClick={handleClick}>
          <span className="c-main-nav__icon c-main-nav__icon--close"><FaRegTimesCircle /></span>
          Exit
        </button>
      </Fragment>     
    )
  } else {
    return (
      <Fragment>
        <button className="c-main-nav__button" name="faq" onClick={handleClick}>
          <span className="c-main-nav__icon"><FaRegLightbulb /></span>
            Learn More About Your Results
          </button>
      </Fragment>  
    )
  }
}

class FAQButton extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isShowingFAQ: this.props.isShowingFAQ
    };
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    Event("INTERNAL LINK", "FAQ")
    window.scrollTo(0,0)
    if (this.state.isShowingFAQ === true) {
      store.dispatch(hideFAQ())
    } else {
      store.dispatch(showFAQ())
    }
    this.setState(state => ({
      isShowingFAQ: !state.isShowingFAQ
    }));
  }

  render() {
    return (
      <div className="c-main-nav__item">
        <ResultsExplainedButton isShowingFAQ={this.state.isShowingFAQ} handleClick={this.handleClick} />
      </div>
    )
  }
}


function SearchBar(props) {
  if (props.isShowingFAQ) {
    return null
  } else {
    return <SearchBarForm />
  }
}

// Main Nav

class MainNav extends React.Component {
  render() {
    const darkContainer = this.props.isShowingFAQ ? " background-color--black c-padding-bottom--zero" : " background-color--bg-color"
    const darkNav = this.props.isShowingFAQ ? " background-color--black c-position--relative c-padding-bottom--zero c-top--zero" : " background-color--bg-color"
    return (
      <div className={`c-main-nav-container + ${darkContainer}`}>
        <div className={`l-container c-main-nav + ${darkNav}`}>
          <nav className="c-main-nav__section c-main-nav__section--left">
            <a className="c-main-nav__img-link" href="/">
              <img className="c-main-nav__item c-main-nav__img" alt="FakeFriends Logo" src='/img/fakefriends-blue.svg' />
            </a>
            <SearchBar isAuditing={this.props.auditData} isShowingFAQ={this.props.isShowingFAQ} />
          </nav>
          <nav className="c-main-nav__section c-main-nav__section--right">
            <FAQButton isShowingFAQ={this.props.isShowingFAQ} />
          </nav>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.data,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditStatusReducer.results,
    isShowingFAQ: state.modalReducer.faq,
});

export default connect(mapStateToProps)(MainNav);