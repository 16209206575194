// Imports

import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import submitFormAuditUserWatcherSaga from './sagas/audit-user.js'

// Sagas

const sagas = [
  submitFormAuditUserWatcherSaga,
  routinePromiseWatcherSaga,
];

export default sagas;