
// Imports

import { color, layout, space, typography, variant } from 'styled-system'
import styled from 'styled-components';

// Variants

const imageVariants = {
  smallProfile: {
    height: '36px',
    width: '36px',
    borderRadius: '100%'
  },
  countryFlag: {
    height: '14px',
    width: '24px',
  },
  standard: {
    height: 'auto',
    width: '100%',
  }
}

// Components

const FFImage = styled.img(
  color,
  space,
  layout,
  typography,
  variant({
    scale: 'images',
    variants: imageVariants,
  })
)

FFImage.defaultProps = {
  variant: 'standard',
}

export { FFImage }