// Imports

import { createAction } from 'redux-actions';

// Actions

const incrementAnalyzingStatus = createAction('INCREMENT_ANALYZING_STATUS');
const resetAnalyzingStatus = createAction('RESET_ANALYZING_STATUS');

export {
  incrementAnalyzingStatus,
  resetAnalyzingStatus
}