// Imports

import React from 'react';
import { connect } from 'react-redux';

import { Paragraph } from '../paragraphs';
import { FlexColumn } from '../layouts';
import { Title } from '../headers';

// Index

class MarketingCard extends React.Component {

  render() {
    const details = "FakeFriends is a fast and reliable tool for authenticating an Instagram account. Using an internal algorithm we calculate the estimated percent of real followers, and provide additional insights into an account's following."
    return (
      <FlexColumn bg="accent" color="secondary" border="1px solid rgba(0,0,0,0.06)" minHeight="0" borderRadius="8px" p={["12px"]}>
        <FlexColumn flexBasis="50%" justifyContent="flex-start" alignItems="flex-start" pb="12px" mb="12px" borderBottom="1px solid black">
          <Title variant="small" fontWeight="bold" color="secondary" mb="8px">Instagram Auditing Tool</Title>
          <Paragraph color="secondary">{details}</Paragraph>
        </FlexColumn>
        <FlexColumn flexBasis="50%" justifyContent="flex-start" alignItems="flex-start" pb="12px" mb="12px" borderBottom="1px solid black">
          <Title variant="small" fontWeight="bold" color="secondary" mb="8px">Version 1.2</Title>
          <Paragraph color="secondary">We added a list of popular audited celebrities to the home page, along with the FakeFriends Ticker — which shows up-to-date information regarding the most recently audited accounts.</Paragraph>
        </FlexColumn>
        <FlexColumn flexBasis="50%" justifyContent="flex-start" alignItems="flex-start">
          <Title variant="small" fontWeight="bold" color="secondary" mb="8px">Up Next</Title>
          <Paragraph color="secondary">Paid features are coming soon!</Paragraph>
        </FlexColumn>
      </FlexColumn>
    )
  }
}


const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  {}
)(MarketingCard)