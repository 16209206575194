// Imports

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './reducers/root'
import sagas from './saga.js'

// Initial State

const initialState = {}

// Middleware

const sagaMiddleware = createSagaMiddleware()

// Store

const middlewares = [thunk, sagaMiddleware]

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middlewares))
)

// Saga

sagas.forEach((saga) => sagaMiddleware.run(saga));

// Export

export default store;