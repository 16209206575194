// Imports

import React from 'react';
import { hydrate, render } from 'react-dom';

import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';

// Index

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}><App /></Provider>, rootElement);
} else {
  render(<Provider store={store}><App /></Provider>, rootElement);
}

