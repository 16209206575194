// Imports

import styled from 'styled-components'
import { border, color, layout, flexbox, position, shadow, space, typography } from 'styled-system'

// Components

export const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  }
)

export const FlexBox = styled(Box)(
  {
    display: 'flex',
  },
  layout,
  shadow,
  flexbox
)

export const FlexRow = styled(Box)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
  border,
  space,
  color,
  layout,
  flexbox,
  position,
  shadow,
  typography
)

export const FlexColumn = styled(Box)(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'flex-start',
    width: '100%',
  },
  border,
  space,
  color,
  layout,
  flexbox,
  position,
  shadow,
  typography
)

export const Container = styled(Box)(
  layout,
  space
)

const Section = styled(FlexBox)(
  {
    as: "section",
    flexWrap: 'wrap',
    width: '100vw'
  },
  color,
  space,
  layout,
  shadow,
)

const FullPageSection = styled(FlexBox)(
  {
    as: "section",
    flexWrap: 'wrap',
    height: 'calc(100vh - 90px - 42px - 20px)',
    justifyContent: "flex-start",
    width: '100vw',
  },
  {
    margin: 0,
    '@media screen and (max-width: 1000px)': {
       height: 'auto',
    },
  },
  color,
  space,
  layout,
  shadow
)

Section.defaultProps = {
  as: 'section'
}

export { Section, FullPageSection }
