// Imports

import Prismic from 'prismic-javascript';

import { GET_COPY_POPULAR_ACCOUNTS } from '../types';
import Client from '../../clients/prismic';
import { returnErrors } from '../errors';

// Actions

// GET copy
export const getCopyPopularAccounts = () => (dispatch, getState) => {
  Client.query(
      Prismic.Predicates.at('document.type', 'popular_account'),
      { pageSize : 50 }
    )
    .then(res => {
      dispatch({
        type: GET_COPY_POPULAR_ACCOUNTS,
        payload: res.results,
      });
    })
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};