// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getRealFollowersColorForPercent } from  '../../utils/color-compensation';
import { TickerWrapper, Ticker, TickerUsername, TickerPercent} from './ticker.js';
import { getLatestAuditsResults } from '../../actions/api/audit-results';

// Header

export class FFTicker extends Component {

  static propTypes = {
    latestAuditResults: PropTypes.array.isRequired,
    getLatestAuditsResults: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getLatestAuditsResults();
    this.timer = setInterval(
        () => this.props.getLatestAuditsResults(),
        (80*1000));
  }

  componentWillUnmount() {
      clearInterval(this.timer);
  }

  render() {
    const { latestAuditResults } = this.props;
    return (
      <TickerWrapper>
        <Ticker>
          {latestAuditResults.map(result => {
              const username = result.username;
              const realUsers = Math.trunc(result.real_users * 100);
              const color = getRealFollowersColorForPercent(result.real_users);
              return (
                  <TickerUsername key={result.id} to={`/u/${username}`}>
                    {username}
                    <TickerPercent color={color}>{realUsers}%</TickerPercent>
                  </TickerUsername>
              )
            })
          }
        </Ticker>
      </TickerWrapper>
    );
  }
}

const mapStateToProps = state => ({
  latestAuditResults: state.audits.latestAuditResults,
})

export default connect(
  mapStateToProps, 
  { getLatestAuditsResults }
)(FFTicker);
