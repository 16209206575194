// Imports

import {
  auditIdle,
  auditCreated,
  auditFetchingFollowers,
  auditFetchingProfiles,
  auditAnalyzingResults,
  auditFinished,
  auditAborted,
  auditFailed,
  } from '../actions/audit-status.js'
import { startAuditReset, endAuditReset } from '../actions/reset.js'
import { handleActions } from 'redux-actions';

// Reducer

const auditStatusReducer = handleActions(
  new Map([
    [auditIdle, state => ({ ...state, status: 'idle' })],
    [auditCreated, state => ({ ...state, status: 'created'})],
    [auditFetchingFollowers, state => ({ ...state, status: 'followers' })],
    [auditFetchingProfiles, state => ({ ...state, status: 'profiles' })],
    [auditAnalyzingResults, state => ({ ...state, status: 'analyzing' })],
    [auditFinished, state => ({ ...state, status: 'finished' })],
    [auditAborted, state => ({ ...state, status: 'aborted' })],
    [auditFailed, state => ({ ...state, status: 'failed' })],
    [startAuditReset, state => ({ ...state, status: 'resetting' })],
    [endAuditReset, state => ({ ...state, status: 'idle' })]
  ]),
  { status: 'idle' }
)

export default auditStatusReducer