// Imports

import React from 'react'

import { submitFormAuditUserRoutine } from '../../actions/routines.js';
import { ModalView } from '../tracking/tracking.js'
import IndexPage from '../pages/index/index.js'
import store from '../../store.js'

// Preloaded Index Page Wrapper

class PreloadedIndexPageWrapper extends React.Component {

  componentWillMount() {
    // We want to fireoff the calls for the username.
    const username = this.props.location.pathname.substr(3)
    const values = {'values': {'username': username}}
    console.log(values)
    store.dispatch(submitFormAuditUserRoutine.trigger(values))
  }

  render () {
    ModalView('/:username')
    return <IndexPage />
  }
}

export default PreloadedIndexPageWrapper;