// Imports

import { combineReducers } from 'redux';
import { reducer as formReducer } from "redux-form";

import analyzingStatusReducer from './analyzing-status.js';
import submitFormAuditUserReducer from './audit-user.js';
import verifyUsernameReducer from './verify-username.js';
import auditStatusReducer from './audit-status.js';
import auditDataReducer from './audit-data.js';
import messagesReducer from './messages.js';
import auditResetReducer from './reset.js';
import auditsReducer from './api/audits';
import errorsReducer from './errors.js';
import loaderReducer from './loader.js';
import modalReducer from './modal.js';
import copyReducer from './api/copy';

//  Reducers

export default combineReducers({
  form: formReducer,
  copy: copyReducer,
  modalReducer,
  loaderReducer,
  errorsReducer,
  audits: auditsReducer,
  messagesReducer,
  auditDataReducer,
  auditResetReducer,
  auditStatusReducer,
  verifyUsernameReducer,
  analyzingStatusReducer,
  submitFormAuditUserReducer,
})