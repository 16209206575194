// Imports

import {
  showFAQ,
  hideFAQ,
  } from '../actions/faq.js'
import { handleActions } from 'redux-actions';

// Reducer

const modalReducer = handleActions(
  new Map([
    [showFAQ, state => ({ ...state, faq: true })],
    [hideFAQ, state => ({ ...state, faq: false })],
  ]),
  { faq: false }
)

export default modalReducer