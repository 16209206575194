// Imports

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FiShare } from 'react-icons/fi'
import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { createMessage } from '../../../../actions/messages';
import './share-button.css'


// Component

class ShareButton extends Component {

  static propTypes = {
    createMessage: PropTypes.func.isRequired,
  };

  onCopy = e => {
    this.props.createMessage({ auditURLCopiedToClipboard: 'URL copied to clipboard'});
  }

  render() {
    const { username } = this.props;
    const url = `https://www.fakefriends.io/u/${username}`
    return (
      <CopyToClipboard text={url} onCopy={this.onCopy}>
        <button className="c-share-button" onClick={this.onClick}>
          <div className="c-share-button__inner">
            <FiShare size={20} />
            <h3 className="c-share-button__title">Share</h3>
          </div>
        </button>
      </CopyToClipboard>
    )
  }
}

const mapStateToProps = state => ({
  username: state.submitFormAuditUserReducer.audit.username
})

export default connect(mapStateToProps, { createMessage })(ShareButton);