// Imports

import React from 'react';
import { FaCheck, FaTimes, FaInfo } from 'react-icons/fa'

import './alert-template.css'

// Alerts

const AlertTemplate = ({ style, options, message, close }) => (
  <div className="c-alert-template__container">
    {options.type === 'info' && <div className="c-alert-template__icon c-alert-template__icon--info"><FaInfo /></div>}
    {options.type === 'success' && <div className="c-alert-template__icon c-alert-template__icon--success"><FaCheck /></div>}
    {options.type === 'error' && <div className="c-alert-template__icon c-alert-template__icon-error"><FaTimes /></div>}
    <p className="c-alert-template__message">{message}</p>
  </div>
)

export default AlertTemplate;

