// Imports

import React, { useState, useRef } from 'react';
import { FaAngleRight } from "react-icons/fa";

import { Event } from '../tracking/tracking.js';
import './accordion.css'

// Index

export default function Accordion(props) {
  
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setOpacity, setOpacityState] = useState("0")
  const [setRotate, setRotateState] = useState("c-accordion__icon")
  
  const content = useRef(null);

  function toggleAccordion() {
    if (setActive === "") {
      Event("FAQ", props.eventTitle)
    }
    setActiveState(setActive === "" ? "c-accordion--active" : "")
    setHeightState(
      setActive === "c-accordion--active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setOpacityState(
      setActive === "c-accordion--active" ? "0" : "1"
    );
    setRotateState(
      setActive === "c-accordion--active" ? "c-accordion__icon" : "c-accordion__icon c-rotate"
    );
  }

  return (
    <div className="c-accordion">
      <div className="c-accordion__section">
       <button className={`c-accordion__button ${setActive}`} onClick={toggleAccordion}>
         <p className="c-accordion__title">{props.title}</p>
         <span className={`${setRotate}`}><FaAngleRight /></span>
       </button>
       <div ref={content} style={{ maxHeight: `${setHeight}`, opacity: `${setOpacity}` }} className="c-accordion__content">
         <div
           className="c-accordion__text"
           dangerouslySetInnerHTML={{ __html: props.content }}
         />
       </div>
     </div>
    </div>
  )
}