// Imports

import { CREATE_MESSAGE } from './types';

// Actions

// CREATE MESSAGE
export const createMessage = msg => {
  return {
    type: CREATE_MESSAGE,
    payload: msg,
  };
};
