// Imports

import { FaCheckCircle, FaTimesCircle, FaLock, FaMinus } from "react-icons/fa"
import React, { Component, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import Bowser from "bowser"

import { connect } from 'react-redux'
import Numeral from 'numeral'

import { truncateText } from '../../../../utils/text.js'
import RelevancyBar from '../relevancy-bar/relevancy-bar.js'
import './profile-crawl-row.css'

// Subcomponents

const Real = ({isReal, username, row}) => {
  const animationDelaySec = String((0.250 * row) + 1)
  const ios = Bowser.ios
  if (isReal) {
    const realDataTip = '<span>@' + username + ' is a <b class="bold color-green">real</b> account</span>'
    return (
      <div className="c-profile-crawl-col--real">
        <FaCheckCircle data-tip={realDataTip} data-for='react-tooltip-real' style={{animationDelay: animationDelaySec + 's'}} className="c-real pulse-on-display" alt="Real" />
        <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} effect='solid' place='top' multiline={true} html={true} id='react-tooltip-real' />
      </div>
    
    )
  } else {
    const fakeDataTip = '<span>@' + username + ' is a <b class="bold color-red">fake</b> account</span>'
    return (
      <div className="c-profile-crawl-col--real">
        <FaTimesCircle data-tip={fakeDataTip} data-for='react-tooltip-fake' style={{animationDelay: animationDelaySec + 's'}} className="c-fake pulse-on-display" alt="Fake" />
        <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} effect='solid' place='top' multiline={true} html={true} id='react-tooltip-fake' />
      </div>
    
    )
  }
}

const Count = ({count, classNames}) => {
  return <h5 className={classNames}>{count}</h5>
}

const Private = ({isPrivate, username}) => {
  const ios = Bowser.ios
  const privateDataTip = '<span>@' + username + ' is a <b class="bold">private</b> account</span>'
  if (isPrivate) {
    return (
      <div className="c-profile-crawl-col--private">
        <FaLock data-tip={privateDataTip} data-for='react-tooltip-private' className="c-lock" size={14}/>
        <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} effect='solid' place='top' multiline={true} html={true} id='react-tooltip-private' />
      </div>
    )
  } else {
    return (
      <div className="c-profile-crawl-col--private">
        <FaMinus className="c-minus" size={14}/>
      </div>
    )
  }
}

const Verified = ({isVerified, username}) => {
  const ios = Bowser.ios
  if (isVerified) {
    const verifiedDataTip = '<span>@' + username + ' is a <b class="bold color-insta-verified">verified</b> account</span>'
    return (
      <div className="c-profile-crawl-col--verified">
        <img data-tip={verifiedDataTip} data-for='react-tooltip-verified' className="c-verified pulse-on-display animation-delay-one-sec" alt="Verified" src='/img/insta-verified.png'/>
        <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='top' multiline={true} html={true} id='react-tooltip-verified' />
      </div>
    )
  } else {
    return (
      <div className="c-profile-crawl-col--verified">
        <FaMinus className="c-minus" size={14}/>
      </div>
    )
  }
}

// Profile Crawl Row

class ProfileCrawlRow extends Component {
  render() {
    const row = this.props.row
    const profile = this.props.profile
    const truncatedUsername = truncateText(profile.username, 24, '...')
    const profileURL = 'https://www.instagram.com/' + profile.username + '/'
    const formattedRatio = (profile.followed_by_to_follow_ratio < 0.1) ? Numeral(profile.followed_by_to_follow_ratio).format('0.00') : Numeral(profile.followed_by_to_follow_ratio).format('0.0')
    return (
      <Fragment>
        <div className="c-profile-crawl-row">
          <div className="c-profile-crawl-col c-profile-crawl-col--spacer"><br/></div>
          <div className="c-profile-crawl-col c-profile-crawl-col--avatar">
            <p className="c-profile-crawl__profile-pic__link" ><img className="c-profile-crawl__profile-pic" alt="Avatar" src={profile.profile_pic_url}/></p>
          </div>
          <a className="c-profile-crawl-col c-profile-crawl-col--username" href={profileURL} rel="noopener noreferrer" target="_blank">@{truncatedUsername}</a>
          <Real isReal={!profile.is_fake} username={profile.username} row={row}/>
          <RelevancyBar realness={profile.realness} row={row}/>
          <Count classNames="c-profile-crawl-col c-profile-crawl-col--count c-profile-crawl-col--count-followers" count={Numeral(profile.followed_by_count).format()} />
          <Count classNames="c-profile-crawl-col c-profile-crawl-col--count" count={Numeral(profile.follow_count).format()} />
          <Count classNames="c-profile-crawl-col c-profile-crawl-col--count c-profile-crawl-col--count-ratio" count={formattedRatio} />
          <Count classNames="c-profile-crawl-col c-profile-crawl-col--count" count={Numeral(profile.post_count).format()} />
          <Private isPrivate={profile.is_private} username={profile.username}  row={row} />
          <Verified isVerified={profile.is_verified} username={profile.username}  row={row}/>
        </div>
      </Fragment>      
    )
  }
}

const mapStateToProps = (state) => ({
    auditData: state.submitFormAuditUserReducer.data,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps)(ProfileCrawlRow);