// Imports

import React from "react";
import styled from 'styled-components';
import { color, flexbox, layout, space, typography } from 'styled-system';

import { getRealFollowersColorForPercent, getFollowerRelevancyColorForPercent } from '../../utils/color-compensation'; 
import { formatNum, suffixForNum } from '../../utils/numbers';
import { toTitleCase } from '../../utils/text';
import { Link } from 'react-router-dom';
import { FlexRow } from '../layouts';
import { FFImage } from '../images';

// Sub Components

const AccountRow = styled(FlexRow)(
  {
    alignItems: "center",
    borderRadius: "4px",
    cursor: "pointer",
    justifyContent: "flex-start",
    minHeight: "52px",
    paddingLeft: "12px",
    paddingRight: "12px",
    width: "100%",
    "&:hover": {
      color: "#2E3A49",
      border: "1px solid #DFE1E5",
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      cursor: "pointer",
      justifyContent: "flex-start",
      minHeight: "52px",
      paddingLeft: "12px",
      paddingRight: "12px",
      textDecoration: 'none !important',
      width: "100%",
    }
  },
  color,
  flexbox,
  space,
  layout,
  typography,
)

export const RowItem = styled.div(
  {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexGrow: 0,
    justifyContent: "center"
  },
  color,
  flexbox,
  space,
  layout,
  typography,
)

// Popular Account Row

const PopularAccountRow = ({account, auditResult, bg}) => {
  const realScore = auditResult.real_users;
  const realColor = getRealFollowersColorForPercent(realScore);
  const realPercent = Math.trunc(realScore * 100);
  const relevScore = auditResult.avg_relevancy;
  const relevColor = getFollowerRelevancyColorForPercent(relevScore)
  const relevPercent = Math.trunc(relevScore * 100);
  const formattedFollowersCount = Math.trunc(formatNum(auditResult.followers_count))
  const suffixForFollowersCount = suffixForNum(auditResult.followers_count)
  return (
    <AccountRow as={Link} to={`/u/${auditResult.username}`} bg={bg}>
      <RowItem flexBasis={["15%", "8%", "8%"]} justifyContent="flex-start">
        <FFImage variant="smallProfile" src={auditResult.profile_pic_url}
          onError={(e)=>{e.target.onerror = null; e.target.src="/img/favicon-64x64.png"}}/>
      </RowItem>
      <RowItem flexBasis={["45%", "29%", "22%"]} fontFamily="Roboto" fontSize="14px" fontWeight="500" textAlign="left" justifyContent="flex-start">
        {account.data.full_name[0].text}
      </RowItem>
      <RowItem flexBasis={["17%", "14%", "14%"]} fontFamily="Roboto" fontSize="16px" fontWeight="700" color={realColor}>
        {realPercent}%
      </RowItem>
      <RowItem flexBasis={["7%", "7%", "7%"]} display={["none", "none", "flex"]} fontFamily="Roboto" fontSize="16px" fontWeight="700" color={relevColor}>
        {relevPercent}%
      </RowItem>
      <RowItem flexBasis={["23%", "26%", "23%"]} display={["none", "flex", "flex"]} fontFamily="Roboto" fontSize="13px" fontWeight="500">
        {toTitleCase(account.data.profession[0].text)}
      </RowItem>
      <RowItem flexBasis={["14%", "7%", "7%"]} display={["none", "flex", "flex"]}>
        <FFImage variant="countryFlag" src={account.data.country_icon_url.url}/>
       </RowItem>
      <RowItem flexBasis={["23%", "15%", "18%"]} fontFamily="Roboto" fontSize="13px" fontWeight="500">
        {formattedFollowersCount}{suffixForFollowersCount}
      </RowItem>
    </AccountRow>
  )
};

export { PopularAccountRow };