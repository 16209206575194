// Imports

import {
  auditResultsReceived,
  auditProfileCrawlReceived,
  auditProfileCrawlsReceived,
  auditRecentPostsCrawlReceived,
  } from '../actions/audit-data.js'
import { startAuditReset } from '../actions/reset.js'
import { handleActions } from 'redux-actions';

// Reducer

const auditDataReducer = handleActions(
  new Map([
    [auditResultsReceived, (state, action)  => ({ ...state,  results: action.payload })],
    [auditProfileCrawlReceived, (state, action) => ({ ...state, profileCrawl: action.payload })],
    [auditProfileCrawlsReceived, (state, action) => ({ ...state, profileCrawls: action.payload })],
    [auditRecentPostsCrawlReceived, (state, action) => ({ ...state, recentPostsCrawl: action.payload })],
    [startAuditReset, (state) => ({ ...state, results: null, profileCrawl: null, profileCrawls: null, recentPostsCrawl: null })],
  ]),
  { results: null, profileCrawl: null, profileCrawls: null, recentPostsCrawl: null }
)

export default auditDataReducer