// Imports

import React, { Component, Fragment } from 'react'
import { FaInfoCircle } from "react-icons/fa"
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import Bowser from "bowser"

import { suffixForNum, formatNum } from '../../../../utils/numbers.js'
import AuditResultsLoading from './audit-results-loading.js'
import HalfPieChart from '../pie-chart/pie-chart.js'
import { Event } from '../../../tracking/tracking.js'
import './audit-results.css'

// Const

const realFollowersDataTip = "The percent of active and reachable followers as determined by the FakeFriends® algorithm."
const followerRelevancyDataTip = "Relevancy is calculated from account variables to determine a user's importance on Instagram."
const sampleSizeDataTip = "The number of accounts selected from the user\n's followers to perform the audit."

// Audit Results

class AuditResults extends Component {
  render() {
    const ios = Bowser.ios
    let { auditRecentPostsCrawl, auditResults, auditStatus } = this.props
    if (auditResults === null || auditRecentPostsCrawl === null) {
      if (auditStatus !== null && auditStatus !== undefined && (auditStatus === 'failed' || auditStatus === 'aborted')) {
          return null
      } else {
        return <AuditResultsLoading />  
      }
    } else {
      const formattedLikes = formatNum(auditRecentPostsCrawl.avg_likes)
      const formattedComments = formatNum(auditRecentPostsCrawl.avg_comments)
      const formattedPosts = formatNum(auditRecentPostsCrawl.posts_per_week)
      const suffixForLikes = suffixForNum(auditRecentPostsCrawl.avg_likes)
      const suffixForComments = suffixForNum(auditRecentPostsCrawl.avg_comments)
      const suffixForPosts = suffixForNum(auditRecentPostsCrawl.posts_per_week)
      const formattedRealFollowers = formatNum(auditResults.real_users)
      const formattedRelevancy = formatNum(auditResults.avg_relevancy)
      return (
        <Fragment>
          <div className="c-audit-results-container">
            <div className="c-audit-results-card">
              <div className="c-audit-results-card__header">
                <h5 className="c-audit-results-card__title">Real Followers</h5>
                <FaInfoCircle data-tip={realFollowersDataTip} data-for='react-tooltip-real-followers' onMouseOver={()=> Event("ON MOUSE OVER", "Real Followers")} className="c-info-circle" size={16}/>
                <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='top' multiline={true} id='react-tooltip-real-followers' />
              </div>
              <p className="c-audit-results-card__subtitle">Average: 62%</p>
              <HalfPieChart type='realFollowers' name={'Real Followers'} percent={formattedRealFollowers} />
            </div>
            <div className="c-audit-results-card">
              <div className="c-audit-results-card__header">
                <h5 className="c-audit-results-card__title">Avg Follower Relevancy</h5>
                <FaInfoCircle data-tip={followerRelevancyDataTip} data-for='react-tooltip-follower-relevancy' onMouseOver={()=> Event("ON MOUSE OVER", "Follower Relevancy")} className="c-info-circle" size={16}/>
                <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='top' multiline={true} id='react-tooltip-follower-relevancy' />
              </div>
              <HalfPieChart type='followerRelevancy' name={'Follower Relevancy'} percent={formattedRelevancy} />
            </div>
            <div className="c-audit-results-card">
              <div className="c-audit-results-card__header">
                <h5 className="c-audit-results-card__title">Sample Size</h5>
                <FaInfoCircle data-tip={sampleSizeDataTip} data-for='react-tooltip-sample-size' onMouseOver={()=> Event("ON MOUSE OVER", "Sample Size")} className="c-info-circle" size={16}/>
                <ReactTooltip event={ios ? 'click' : undefined} globalEventOff={ios ? 'click' : undefined} className="c-tooltip" effect='solid' place='top' multiline={true} id='react-tooltip-sample-size' />
              </div>
              <div className="c-audit-results-card__samples">
                <div className="c-audit-results-card__sample c-audit-results-card__sample--real">
                  <h3 className="c-audit-results-card__sample-size c-audit-results-card__sample-size--real">{auditResults.sample_size}</h3>
                  <p className="c-audit-results-card__sample-label">Total</p>
                </div>
                <div className="c-audit-results-card__sample c-audit-results-card__sample--fake">
                  <h3 className="c-audit-results-card__sample-size c-audit-results-card__sample-size--fake">{auditResults.fake_count}</h3>
                  <p className="c-audit-results-card__sample-label">Fake</p>
                </div>
              </div>
            </div>
            <div className="c-audit-results-card c-audit-results-card--table">
              <div className="c-audit-results-card__row c-audit-results-card__row--stat">
                <h3 className="c-audit-results-card__stat-title">
                  Likes<br/>
                  <span className="c-audit-results-card__stat-label">per posts</span>
                </h3>
                <h2 className="c-audit-results-card__stat-value">{ formattedLikes + suffixForLikes }</h2>
              </div>
              <div className="c-audit-results-card__row c-audit-results-card__row--stat">
                <h3 className="c-audit-results-card__stat-title">
                  Comments<br/>
                  <span className="c-audit-results-card__stat-label">per posts</span>
                </h3>
                <h2 className="c-audit-results-card__stat-value">{ formattedComments + suffixForComments }</h2>
              </div>
              <div className="c-audit-results-card__row c-audit-results-card__row--stat">
                <h3 className="c-audit-results-card__stat-title">
                  Weekly<br/>
                  <span className="c-audit-results-card__stat-label">posts</span>
                </h3>
                <h2 className="c-audit-results-card__stat-value">{ formattedPosts + suffixForPosts }</h2>
              </div>
            </div>
          </div>
        </Fragment>   
      )
    }
  }
}

const mapStateToProps = (state) => ({
    audit: state.submitFormAuditUserReducer.audit,
    auditStatus: state.auditStatusReducer.status,
    auditResults: state.auditDataReducer.results,
    auditRecentPostsCrawl: state.auditDataReducer.recentPostsCrawl,
    auditProfileCrawl: state.auditDataReducer.profileCrawl,
    auditProfileCrawls: state.auditDataReducer.profileCrawls,
});

export default connect(mapStateToProps)(AuditResults);