// Imports

import {
  loaderMount,
  loaderUnmount,
  } from '../actions/loader.js'
import { handleActions } from 'redux-actions';

// Reducer

const loaderReducer = handleActions(
  new Map([
    [loaderMount, state => ({ ...state, mount: true })],
    [loaderUnmount, state => ({ ...state, mount: false })],
  ]),
  { mount: false }
)

export default loaderReducer