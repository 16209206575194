// Imports

import axios from 'axios';

import { 
  auditResultURL,
  auditsResultsURL,
  } from '../../urls';
import {
  GET_AUDIT_RESULT,
  GET_AUDITS_RESULTS,
  GET_LATEST_AUDIT_RESULTS
  } from '../types';
import { returnErrors } from '../errors';

// Actions

// GET audit-result
export const getAuditResult = (auditLookup) => (dispatch, getState) => {
  const url = auditResultURL(auditLookup);
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_AUDIT_RESULT,
        payload: res.data,
      });
    })
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET audit-results
export const getAuditsResults = (usernames, scheduled) => (dispatch, getState) => {
  const strUsernames = JSON.stringify(usernames)
  const usernameQueryParams = `?usernames=${strUsernames}`
  let url = auditsResultsURL;
  url = `${url}${usernameQueryParams}`
  if (scheduled === true) {
    url = `${url}&scheduled=True`
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_AUDITS_RESULTS,
        payload: res.data,
      });
    })
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}

// GET latest audit-results
export const getLatestAuditsResults = () => (dispatch, getState) => {
  axios
    .get(auditsResultsURL)
    .then(res => {
      dispatch({
        type: GET_LATEST_AUDIT_RESULTS,
        payload: res.data,
      });
    })
    .catch(err => dispatch(returnErrors(err.response.data, err.response.status)));
}
