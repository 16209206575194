// Imports

import {
  GET_COPY_POPULAR_ACCOUNTS
} from '../../actions/types';

// Reducers

const initialState = {
  popularAccounts: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COPY_POPULAR_ACCOUNTS:
      return {
        ...state,
        popularAccounts: action.payload
      };
    default:
      return state;
  }
}
