// Imports

import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import CountUp from 'react-countup'

import {
  getRealFollowersColorForPercent,
  getFollowerRelevancyColorForPercent
  } from '../../../../utils/color-compensation.js'
import './pie-chart.css'

// Helpers



// Half Pie Chart

class HalfPieChart extends Component {
  render() {
    const { name, percent, type } = this.props
    const compensation = 1 - percent
    const roundedPercent = Math.round(percent * 100)
    const data = [{name: name, value: percent}, {name: name, value: compensation}]
    let colors = [];
    if (type === 'realFollowers') {
      colors = [getRealFollowersColorForPercent(percent), '#DFE1E5'];
    } else {
      colors = [getFollowerRelevancyColorForPercent(percent), '#DFE1E5'];
    }
    return (
      <div className="c-pie-chart-container">
        <ResponsiveContainer width={200} height={100}>
          <PieChart onMouseEnter={this.onPieEnter}>
            <Pie data={data} cy={100} startAngle={180} endAngle={0} innerRadius={80} outerRadius={100}  fill="#FFF" paddingAngle={3}>
             { data.map((entry, index) => <Cell fill={colors[index % colors.length]}/>) }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="c-pie-chart__value-container">
          <CountUp className="c-pie-chart__value" delay={0.5} start={0} end={roundedPercent} duration={2} decimals={0}></CountUp>
          <span className="c-pie-chart__percent">%</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    auditResults: state.auditDataReducer.results,
});

export default connect(mapStateToProps)(HalfPieChart);