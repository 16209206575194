// Imports

import { SubmissionError } from 'redux-form';
import { call, put, takeEvery } from 'redux-saga/effects';

import { submitFormAuditUserRoutine } from '../actions/routines.js';
import { startAuditReset, endAuditReset } from '../actions/reset.js';
import { loaderMount, loaderUnmount } from '../actions/loader.js';
import FakeFriendsAPI from '../clients/fakefriends.js'
import store from '../store.js'

// Saga

export default function* submitFormAuditUserWatcherSaga() {
  yield takeEvery(submitFormAuditUserRoutine.TRIGGER, validate);
}

function* validate(action) {

  store.dispatch(startAuditReset())
  store.dispatch(loaderUnmount())
  store.dispatch(loaderMount())

  // Redux-form pass form values and component props to submit handler
  // so they passed to trigger action as an action payload.
  const { values } = action.payload

  if (!values.username) {
    // client-side validation failed
    // const errors = getFormErrors(values, props);
    // reject promise given to redux-form, pass errors as SubmissionError object according to redux-form docs
      yield put(submitFormAuditUserRoutine.failure(new SubmissionError('error')))
  } else {
    // send form data to server
    yield call(sendFormDataToServer, values)
  }

  // trigger fulfill action to end routine lifecycle
  yield put(submitFormAuditUserRoutine.fulfill())
}

function* sendFormDataToServer(formData) {
  try {
    // trigger request action
    yield put(submitFormAuditUserRoutine.request())
    // perform request to '/submit' to send form data
    const response = yield call(FakeFriendsAPI.auditUsername, formData.username);
    // if request successfully finished
    yield put(submitFormAuditUserRoutine.success(response.data))
    yield put(store.dispatch(endAuditReset()))
  } catch (error) {
    // if request failed
    console.log(error.message)
    yield put(submitFormAuditUserRoutine.failure(new SubmissionError({ _error: error.message })))
  }
}